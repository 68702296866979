import loading_gif from '../../../assets/img/loading.gif';

/**
  Helper method for converting the response data from the server
  into the imageLinks array stored by a CarouselState object.

  @param [Object] imageLinksMap - See constructor of CarouselState.
  @return [Array] The converted image links list.
*/
const convertImageLinksMapToList = (imageLinksMap) => {
  if (!imageLinksMap) {
    return [["loading...", loading_gif]];
  }

  let imageLinks = [];
  imageLinks.push(["Red, Green, Blue", imageLinksMap['RGB']]);
  imageLinks.push(["Red, Blue, Green", imageLinksMap['RBG']]);
  imageLinks.push(["Green, Red, Blue", imageLinksMap['GRB']]);
  imageLinks.push(["Green, Blue, Red", imageLinksMap['GBR']]);
  imageLinks.push(["Blue, Red, Green", imageLinksMap['BRG']]);
  imageLinks.push(["Blue, Green, Red", imageLinksMap['BGR']]);

  imageLinks.push(["Cyan, Magenta, Yellow", imageLinksMap['CMY']]);
  imageLinks.push(["Cyan, Yellow, Magenta", imageLinksMap['CYM']]);
  imageLinks.push(["Magenta, Cyan, Yellow", imageLinksMap['MCY']]);
  imageLinks.push(["Magenta, Yellow, Cyan", imageLinksMap['MYC']]);
  imageLinks.push(["Yellow, Cyan, Magenta", imageLinksMap['YCM']]);
  imageLinks.push(["Yellow, Magenta, Cyan", imageLinksMap['YMC']]);

  return imageLinks;
}

/**
  Manages the state of the carousel image view.
*/
export default class CarouselState {
  /**
    The position (within imageLinks) of graph the user is currently viewing
  */
  imageIndex;

  /**
    A list of the images within the carousel.
    Items within this list are defined as ordered pairs (via arrays) where
    the left (first) element is the title to display with the image; while the
    right (second) element is the link to the image.
  */
  imageLinks;

  /**
    Whether or not the carousel is currently open
  */
  open;

  /**
    The timestamp of the last click recorded on the carousel image
  */
  lastClick;

  /**
    A lambda `(CarouselState) => ()` that updates the state of whichever react component
    includes this CarouselState within it's react state.
  */
  updateCallback;

  /**
    @param [(CarouselState) => ()] updateCallback The callback to update the state of the component that
      contains this CarouselState within it's react state.
    @option [Boolean] open - Whether or not the carousel should be open (defaults to true)
    @option [Object] imageLinksMap - The images to display in the carousel, defined through a hash that
      maps color code (RGB CMY etc) to the link for the graph with that color code. If this option
      is not provided, then the loading gif will be used for the carousel.
  */
  constructor(updateCallback, options = {}) {
    this.imageIndex = 0;
    this.lastClick = null;

    // Default to an open carousel
    if (options.open == null) {
      this.open = true;
    } else {
      this.open = options.open;
    }

    this.updateCallback = updateCallback;
    this.imageLinks = convertImageLinksMapToList(options.imageLinksMap);
  }

  /**
    @return [String] The title of the current image being displayed by the carousel.
  */
  currentTitle() {
    let info = this.imageLinks[this.imageIndex];
    if (!info) { return; }

    return info[0];
  }

  /**
    @return [String] The source link of the current image being displayed by the carousel.
  */
  currentSource() {
    let info = this.imageLinks[this.imageIndex];
    if (!info) { return; }

    return info[1];
  }

  /**
    @return [Boolean] True if the carousel is currently showing the loading gif, false if
      it's showing graphs.
  */
  isLoading() {
    return this.imageLinks.length === 1;
  }

  /**
    @return [Boolean] True if the current time is too close to the last time the user
      clicked on the graph (> 200 miliseconds).
  */
  clickedTooSoon() {
    let currentTime = new Date().getTime();
    return !this.lastClick && (currentTime - this.lastClick) < 200
  }

  /**
    Updates the images stored by this carousel to the ones given.

    @param [imageLinksMap] The updated images to display in the carousel. See
      the constructor docs for more details.
    @return [CarouselState] A reference to this carousel state.
  */
  updateImageLinks(imageLinksMap) {
    this.imageLinks = convertImageLinksMapToList(imageLinksMap);
    this.imageIndex = 0;
    return this;
  }

  /**
    Updates the last click time to the current time.

    @return [CarouselState] A reference to this carousel state.
  */
  updateLastClick() {
    this.lastClick = new Date().getTime();
    return this;
  }

  /**
    Updates the state to specify the carousel should be open.

    @return [CarouselState] A reference to this carousel state.
  */
  openCarousel() {
    this.open = true;
    return this;
  }

  /**
    Updates the state to specify the carousel should be closed.

    @return [CarouselState] A reference to this carousel state.
  */
  closeCarousel() {
    this.open = false;
    return this;
  }

  /**
    Updates the state to specify the carousel should move to the next image.

    @return [CarouselState] A reference to this carousel state.
  */
  nextImage() {
    let length = this.imageLinks.length;
    if (length < 2) {
      return this;
    }

    this.imageIndex = (this.imageIndex + 1) % (length);
    return this;
  }

  /**
    Updates the state to specify the carousel should move to the previous image.

    @return [CarouselState] A reference to this carousel state.
  */
  previousImage() {
    let length = this.imageLinks.length;
    if (length < 2) {
      return this;
    }

    if (this.imageIndex === 0) {
      this.imageIndex = length - 1;
    } else {
      this.imageIndex -= 1;
    }

    return this;
  }

  /**
    Forces all updates to this carousel state to take effect by triggering
    a set state call in the react component that has this carousel state within
    it's react state.

    @return [void]
  */
  forceUpdate() {
    this.updateCallback(this);
  }
}
