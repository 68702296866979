import React from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/**
  Defines the function, x-domain, and y-domain inputs for the console form.

  @param [Integer] index - The index of the function/domains this input corresponds to
  @param [String] function - The current value of the function
  @param [String] xDomain - The current value of the x-domain mapping
  @param [String] yDomain - The current value of the y-domain mapping
  @param [(Event) => ()] onChange - The onChange listener for user updates to the function/domains
*/
const FunctionInput = (props) => {
  let functionName = "function" + props.index;
  let xDomainName = "xDomain" + props.index;
  let yDomainName = "yDomain" + props.index;

  return (
    <div>
      <Row>
        <Col style={{paddingTop: "3vh"}} sm={12} md={4}>
          <Form.Label>Function {props.index}</Form.Label>
          <Form.Control
            placeholder="Your function input"
            name={functionName}
            value={props.function}
            onChange={props.onChange}
            className="console-input"
          />
        </Col>
        <Col style={{paddingTop: "3vh"}} sm={12} md={4}>
          <Form.Label>X-Domain {props.index}</Form.Label>
          <Form.Control
            placeholder="Your X-Domain input"
            name={xDomainName}
            value={props.xDomain}
            onChange={props.onChange}
            className="console-input"
          />
        </Col>
        <Col style={{paddingTop: "3vh"}} sm={12} md={4}>
          <Form.Label>Y-Domain {props.index}</Form.Label>
          <Form.Control
            placeholder="Your Y-Domain input"
            name={yDomainName}
            value={props.yDomain}
            onChange={props.onChange}
            className="console-input"
          />
        </Col>
      </Row>
    </div>
  );
}

export default FunctionInput;
