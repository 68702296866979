import { getRandomInt } from './general';
import ColorGraphConfiguration from '../classes/colorgraph_configuration';

const interestingOperations = [
  { f: 'x & y', x: 'n', y: 'n' },
  { f: 'x | y', x: 'n', y: 'n' },
  { f: 'x || y', x: 'n', y: 'n' },
  { f: 'x => y', x: 'n', y: 'n' },
  { f: 'x <=> y', x: 'n', y: 'n' },
  { f: 'x -& y', x: 'n', y: 'n' },
  { f: 'x -| y', x: 'n', y: 'n' },

  { f: 'sin(x & y)', x: 'n', y: 'n' },
  { f: 'sin(x | y)', x: 'n', y: 'n' },
  { f: 'sin(x || y)', x: 'n', y: 'n' },
  { f: 'sin(x => y)', x: 'n', y: 'n' },
  { f: 'sin(x <=> y)', x: 'n', y: 'n' },
  { f: 'sin(x -& y)', x: 'n', y: 'n' },
  { f: 'sin(x -| y)', x: 'n', y: 'n' },
  { f: 'cos(x & y)', x: 'n', y: 'n' },
  { f: 'cos(x | y)', x: 'n', y: 'n' },
  { f: 'cos(x || y)', x: 'n', y: 'n' },
  { f: 'cos(x => y)', x: 'n', y: 'n' },
  { f: 'cos(x <=> y)', x: 'n', y: 'n' },
  { f: 'cos(x -& y)', x: 'n', y: 'n' },
  { f: 'cos(x -| y)', x: 'n', y: 'n' },

  { f: 'sin(x & y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'sin(x | y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'sin(x || y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'sin(x => y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'sin(x <=> y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'sin(x -& y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'sin(x -| y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x & y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x | y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x || y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x => y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x <=> y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x -& y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x -| y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },

  { f: 'sin(x -& y) || cos(x & y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },

  { f: 'sin(x -& y) // cos(x & y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x -& y) // sin(x & y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'sin(x -| y) // cos(x | y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x -| y) // sin(x | y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },

  { f: 'sin(x & y) // cos(x -& y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x & y) // sin(x -& y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'sin(x | y) // cos(x -| y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'cos(x | y) // sin(x -| y)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },

  { f: 'ln((32*x - 26*y)^2) || ln((32*y - 26*x)^2)', x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)'},

  { f: '(x & y) = 0', x: 'n', y: 'n' },

  { f: '(x & y) // (x + y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x | y) // (x + y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x || y) // (x + y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x => y) // (x + y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x <=> y) // (x + y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -& y) // (x + y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -| y) // (x + y)', x: 'n - 250', y: 'n - 250' },

  { f: '(x & y) // (x - y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x | y) // (x - y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x || y) // (x - y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x => y) // (x - y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x <=> y) // (x - y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -& y) // (x - y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -| y) // (x - y)', x: 'n - 250', y: 'n - 250' },

  { f: '(x & y) // (x // y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x | y) // (x // y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x || y) // (x // y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x => y) // (x // y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x <=> y) // (x // y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -& y) // (x // y)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -| y) // (x // y)', x: 'n - 250', y: 'n - 250' },

  { f: '(x & y) // (y // x)', x: 'n - 250', y: 'n - 250' },
  { f: '(x | y) // (y // x)', x: 'n - 250', y: 'n - 250' },
  { f: '(x || y) // (y // x)', x: 'n - 250', y: 'n - 250' },
  { f: '(x => y) // (y // x)', x: 'n - 250', y: 'n - 250' },
  { f: '(x <=> y) // (y // x)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -& y) // (y // x)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -| y) // (y // x)', x: 'n - 250', y: 'n - 250' },

  { f: '(x & y) // ((x^2 + y^2) // 128)', x: 'n - 250', y: 'n - 250' },
  { f: '(x | y) // ((x^2 + y^2) // 128)', x: 'n - 250', y: 'n - 250' },
  { f: '(x || y) // ((x^2 + y^2) // 128)', x: 'n - 250', y: 'n - 250' },
  { f: '(x => y) // ((x^2 + y^2) // 128)', x: 'n - 250', y: 'n - 250' },
  { f: '(x <=> y) // ((x^2 + y^2) // 128)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -& y) // ((x^2 + y^2) // 128)', x: 'n - 250', y: 'n - 250' },
  { f: '(x -| y) // ((x^2 + y^2) // 128)', x: 'n - 250', y: 'n - 250' },

  { f: 'sin(x^2 + y^2)', x: 'n - 250', y: 'n - 250' },
  { f: 'cos(x^2 + y^2)', x: 'n - 250', y: 'n - 250' },
  { f: 'tan(x^2 + y^2)', x: 'n - 250', y: 'n - 250' },
  { f: 'sin(x^2 + y^2) // cos(x^2 + y^2)', x: 'n - 250', y: 'n - 250' },
  { f: 'sin(x^2 + y^2) // cos(x^2 + y^2)', x: 'ln(n)', y: 'ln(n)' },
  { f: 'cos(x^2 + y^2) // sin(x^2 + y^2)', x: 'n - 250', y: 'n - 250' },
  { f: 'cos(x^2 + y^2) // sin(x^2 + y^2)', x: 'ln(n)', y: 'ln(n)' },

  { f: '(x^2 + y^2) // 50', x: 'n - 250', y: 'n - 250' },
  { f: '(x^2 + y^2) // 250', x: 'n - 250', y: 'n - 250' },
  { f: '(x^2 + y^2) // 500', x: 'n - 250', y: 'n - 250' },
  { f: '(x^2 + y^2) // 5000', x: 'n - 250', y: 'n - 250' },

  { f: '(x^2 + y^2) // 50',   x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },
  { f: '(x^2 + y^2) // 250',  x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },
  { f: '(x^2 + y^2) // 500',  x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },
  { f: '(x^2 + y^2) // 5000', x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },

  { f: '(x^2 + y^2) // (512*x - 901*y)', x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },
  { f: '(x^2 + y^2) // (-512*x + 901*y)', x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },
  { f: '(x^2 + y^2) // (901*x - 512*y)', x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },
  { f: '(x^2 + y^2) // (-901*x + 512*y)', x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },

  { f: '((x^2 + y^2)^6 || x) // ((x^2 + y^2)^6 || y)', x: 'n + 10000', y: 'n + 10000'},

  { f: '(x + y)^2 // (x*y*sin(x + y))', x: 'n', y: 'n'},
  { f: '(x + y)^2 // (x*y*sin(x + y))', x: 'n - 250', y: 'n - 250'},
  { f: '(x*y*cos(x + y)) // (x*y*sin(x + y))', x: 'ln(n - 250)', y: 'ln(n - 250)'},
  { f: '(x + y)^2 // (x*y*sin(x + y))', x: 'ln(n - 250)', y: 'ln(n - 250)'},
  { f: '(x + y)^2 // (x*y*sin(x + y))', x: 'ln(n - 250)^2', y: 'ln(n - 250)^2'},
  { f: '(cos(y)*x + sin(x)*y)^2 // (x*y*sin(x + y))', x: 'ln(n - 250)', y: 'ln(n - 250)'},
  { f: '(cos(y)*x + sin(x)*y)^2 // (x*y*sin(x + y))', x: 'ln(n - 250)^2', y: 'ln(n - 250)^2'},
  { f: '(sin(y)*x^2 + sin(x)*y^2) // (x*y*cos(x)*sin(y))', x: 'ln(n - 250)', y: 'ln(n - 250)'},
  { f: '(sin(y)*x^2 + sin(x)*y^2) // pow(x*y, cos(x)*sin(y))', x: 'ln(n - 250)', y: 'ln(n - 250)'},
  { f: '(sin(y)*x^2 + sin(x)*y^2) // pow(x*y, cos(x)*sin(y))', x: 'ln(n - 250)^2', y: 'ln(n - 250)^2'},
  { f: '(sin(y)*x^2 + sin(x)*y^2) // switch(even(x*y), x*cos(y), y*sin(x))', x: 'ln(n - 250)^2', y: 'ln(n - 250)^2'},

  { f: '(y // x) // (x // y)', x: 'ln(n - 250)^2', y: 'ln(n - 250)^2'},
  { f: '(y // x) // (x // y)', x: 'n', y: 'n' },

  { f: '(x^2 + y^2) // (131*x - 197*y)', x: 'n - 250', y: 'n - 250'},
  { f: '(x^2 + y^2) // (131*x - 197*y)', x: 'n', y: 'n'},

  { f: 'x || (y // x) ' , x: 'n - 250', y: 'n - 250'},
  { f: 'x || (y // x) ' , x: 'ln(n - 250)', y: 'ln(n - 250)'},
  { f: 'x || (y // x) ' , x: 'ln(n - 250)^2', y: 'ln(n - 250)^2'},

  { f: 'within(x -& y, x & y, (x^2 + y^2) // (x - y))', x: 'n', y: 'n'},
  { f: 'within(x -& y, x & y, (x^2 + y^2) // (x - y))', x: 'n - 250', y: 'n - 250'},
  { f: 'within(x -& y, x & y, (x^2 + y^2) // (x - y))', x: 'ln(n - 250)', y: 'ln(n - 250)'},
  { f: 'within(x -& y, x & y, (x^2 + y^2) // (x - y))', x: 'ln(n - 250)^2', y: 'ln(n - 250)^2'},

  { f: 'max(cos(x + y), sin(x - y))', x: 'ln(n - 250)', y: 'ln(n - 250)'},
  { f: 'max(cos(x + y), sin(x - y))', x: 'ln(n - 250)^2', y: 'ln(n - 250)^2'},

  { f: 'dlog(97, 5, x^2 + y^2)', x: 'n - 250', y: 'n - 250'},
  { f: 'dlog(257, 181, x^20 + y^20)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'dlog(257, 181, x^40 + y^40)', x: 'switch(even(n), 250 - n, n - 250)', y: 'switch(even(n), n - 250, 250 - n)' },
  { f: 'dlog(257, 181, x^40 + y^40)', x: 'switch(even(n), n, n^2)', y: 'switch(even(n), n^2, n)' },

];

const basicOperations = [
  { f: 'x', x: 'n', y: 'n' },
  { f: 'y', x: 'n', y: 'n' },
  { f: 'x + y', x: 'n', y: 'n' },
  { f: 'x - y', x: 'n', y: 'n' },
  { f: 'y - x', x: 'n', y: 'n' },
  { f: 'x^2 + y^2', x: 'n - 250', y: 'n - 250' },
  { f: 'x', x: '-1*n', y: 'n' },
  { f: 'y', x: 'n', y: '-1*n' },
  { f: 'x^2', x: 'n - 250', y: 'n - 250' },
  { f: 'y^2', x: 'n - 250', y: 'n - 250' },
  { f: '(x + y)^2', x: 'n - 250', y: 'n - 250' },
  { f: '(x - y)^2', x: 'n - 250', y: 'n - 250' },
  { f: '(y - x)^2', x: 'n - 250', y: 'n - 250' },
  { f: '(x - y) / (x + y)', x: 'n', y: 'n'},
  { f: '(x - y)^2 / (x + y)', x: 'n', y: 'n'},

  { f: 'x', x: 'n', y: 'ln(n)' },
  { f: 'y', x: 'n', y: 'ln(n)' },

  { f: 'not(x + y)', x: 'n', y: 'n' },
  { f: 'not(x - y)', x: 'n', y: 'n' },
  { f: 'not(y - x)', x: 'n', y: 'n' },
  { f: 'not(x^2 + y^2)', x: 'n - 250', y: 'n - 250' },
  { f: 'not(x^2)', x: 'n - 250', y: 'n - 250' },
  { f: 'not(y^2)', x: 'n - 250', y: 'n - 250' },
  { f: 'not(x + y)^2', x: 'n - 250', y: 'n - 250' },
  { f: 'not(x - y)^2', x: 'n - 250', y: 'n - 250' },
  { f: 'not(y - x)^2', x: 'n - 250', y: 'n - 250' },

  { f: 'pos(x) = 0', x: 'n - 250', y: 'n - 250' },
  { f: 'pos(x) = 0', x: '-1*n + 250', y: '-1*n + 250' },
  { f: 'pos(y) = 0', x: 'n - 250', y: 'n - 250' },
  { f: 'pos(y) = 0', x: '-1*n + 250', y: '-1*n + 250' },

  { f: 'min(x, y)', x: 'n', y: 'n'},
  { f: 'min(x^2, y^2)', x: 'n', y: 'n'},
  { f: 'max(x, y)', x: 'n', y: 'n'},
  { f: 'max(x^2, y^2)', x: 'n', y: 'n'},

]

const fullGraphConfigs = [
  {
    function1: 'abs(x) // (x*sin(x + y))',
    xDomain1: 'n - 250',
    yDomain1: 'n - 250',

    function2: 'abs(x) // ((x + y)*sin(x + y))',
    xDomain2: 'n - 250',
    yDomain2: 'n - 250',

    function3: 'abs(x) // (y*sin(x + y))',
    xDomain3: 'n - 250',
    yDomain3: 'n - 250',
  },
  {
    function1: 'abs(x) // (x*sin(x + y))',
    xDomain1: 'ln(n - 250)',
    yDomain1: 'ln(n - 250)',

    function2: 'abs(x) // ((x + y)*sin(x + y))',
    xDomain2: 'ln(n - 250)',
    yDomain2: 'ln(n - 250)',

    function3: 'abs(x) // (y*sin(x + y))',
    xDomain3: 'ln(n - 250)',
    yDomain3: 'ln(n - 250)',
  },
  {
    function1: 'abs(x) // (x*sin(x + y))',
    xDomain1: 'ln(n - 250)^2',
    yDomain1: 'ln(n - 250)^2',

    function2: 'abs(x) // ((x + y)*sin(x + y))',
    xDomain2: 'ln(n - 250)^2',
    yDomain2: 'ln(n - 250)^2',

    function3: 'abs(x) // (y*sin(x + y))',
    xDomain3: 'ln(n - 250)^2',
    yDomain3: 'ln(n - 250)^2',
  },
  // {
  //   function1: '',
  //   xDomain1: '',
  //   yDomain1: '',

  //   function2: '',
  //   xDomain2: '',
  //   yDomain2: '',

  //   function3: '',
  //   xDomain3: '',
  //   yDomain3: '',
  // }
]


export const randomGraph = () => {
  if (!getRandomInt(interestingOperations.length)) {
    return fullGraphConfigs[getRandomInt(fullGraphConfigs.length)];
  }

  let operation1 = interestingOperations[getRandomInt(interestingOperations.length)];
  let op2_position = getRandomInt(basicOperations.length);
  let op3_position = getRandomInt(basicOperations.length);
  if (op3_position === op2_position) {
    if (op3_position === basicOperations.length - 1) {
      op3_position -= 1;
    } else {
      op3_position += 1;
    }
  }
  let operation2 = basicOperations[op2_position];
  let operation3 = basicOperations[op3_position];

  let config = new ColorGraphConfiguration();
  config.function1 = operation1.f;
  config.xDomain1 = operation1.x;
  config.yDomain1 = operation1.y;
  config.function2 = operation2.f;
  config.xDomain2 = operation2.x;
  config.yDomain2 = operation2.y;
  config.function3 = operation3.f;
  config.xDomain3 = operation3.x;
  config.yDomain3 = operation3.y;
  config.height = 500;
  config.width = 500;

  return config;
}

export default randomGraph;
