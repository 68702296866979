import React from 'react';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { browserHistory } from 'react-dom';

import PageNavigation from './components/page-navigation/PageNavigation';

import ArtOfMathProofPage from './pages/art-of-math-proof-page/ArtOfMathProofPage';
import AwkwardStateMachinesPage from './pages/awkward-state-machines-page/AwkwardStateMachinesPage';
import ColorGraphPage from './pages/color-graph-page/ColorGraphPage';
import HomePage from './pages/home-page/HomePage';
import VideosPage from './pages/videos-page/VideosPage';

import 'bootstrap/dist/css/bootstrap.css';
import './app.css';


class App extends React.Component {
  render() {
    return (
      <Router history={browserHistory}>
      <div className="site-container">
        <PageNavigation/>
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route path="/articles/art_of_math_proof" component={ArtOfMathProofPage}></Route>
          <Route path="/awkward_state_machines" component={AwkwardStateMachinesPage}></Route>
          <Route path="/colorgraphing" component={ColorGraphPage}></Route>
          <Route path="/videos" component={VideosPage}></Route>
          <Route component={HomePage}></Route>
        </Switch>
      </div>
      </Router>
    );
  }
}

export default App;
