import React from 'react';

import CloseIcon from '../../../icons/CloseIcon';
import DownloadIcon from '../../../icons/DownloadIcon';
import LeftArrowIcon from '../../../icons/LeftArrowIcon';
import RightArrowIcon from '../../../icons/RightArrowIcon';

import './graph_carousel.css';

/**
  Determines the (x,y) coords of where the user click on the image currently
  being displayed by the modal.

  @param [Event] event - The click event
  @param [<Image>] image - The current image element
  @return [Object] The (x,y) coords
*/
const coordsOfClick = (event, image) => {
  const point = event.changedTouches ? event.changedTouches[0] : event;
  if (point.target.id !== "colorgraph_image") {
    return false;
  }

  const dim = image.getBoundingClientRect();
  const x = point.clientX - dim.left;
  const y = point.clientY - dim.top;

  return { x, y };
}

/**
  Manages the display of modal view of graphs, as well as the carousel navigation for
  switching between graphs.

  @prop [CarouselState] carouselState - The current state of the carousel
  @return [<ModalImage>] The modal element displaying the carousel of images.
*/
const GraphCarousel = (props) => {
  let carouselState = props.carouselState;

  /**
    Updates the modal based on a user click event.
      - If the modal is "loading", the click is ignored
      - If the last click was really close to the current click, then the click is ignored
      - If the click is outside of the image, then the modal is closed
      - If the click is inside the image, and on the right half, then the modal will go to
          the next image.
      - If the click is inside the image, and on the left half, then the modal will go to
          the previous image.
  */
  const imageClickedEvent = (event) => {
    event.preventDefault();

    if (carouselState.isLoading()) {
      return;
    }

    if (event.touches && event.touches.length > 1) {
      // more than one finger, ignored
      return;
    }

    if (event.button !== 0) {
      // Was not a left click, return
      return;
    }

    if (carouselState.clickedTooSoon()) {
      // Screen is registering more clicks than one
      return;
    }

    let image = document.getElementById("colorgraph_image");
    let coords = coordsOfClick(event, image);

    if (!coords) {
      // User clicked out of bounds
      carouselState.closeCarousel().forceUpdate();
      return;
    }

    carouselState.updateLastClick();

    if (coords.x > (image.width / 2)) {
      carouselState.nextImage().forceUpdate();
    } else {
      carouselState.previousImage().forceUpdate();
    }
  }

  /**
    Defines the header displayed when the graph carousel is open.
    The header includes:
      - The color scheme as the title (Red Green Blue, etc)
      - Left & right arrow icons for moving the carousel
      - A close icon for closing the carousel
      - A download icon for downloading the current graph
  */
  const Header = () => {
    /*
      If the size of the font is too large, then the header ends up spilling
      onto multiple lines (and looks terrible). This is an attempt to keep the
      look of the head constant across various sized displays.
    */
    let fontSize = "120%";
    if (window.innerWidth < 315) {
      fontSize = "75%";
    } else if (window.innerWidth < 350) {
      fontSize = "80%";
    } else if (window.innerWidth < 375) {
      fontSize = "95%";
    } else if (window.innerWidth < 390) {
      fontSize = "105%";
    }
    let style = { "fontSize": fontSize }

    let onClose = () => { carouselState.closeCarousel().forceUpdate() };
    let goToPreviousImage = () => { carouselState.previousImage().forceUpdate() };
    let goToNextImage = () => { carouselState.nextImage().forceUpdate() };

    return (
      <div className="__react_modal_image__header">
        <span className="__react_modal_image__icon_menu">
          <a href={carouselState.currentSource()} download="image">
            <DownloadIcon />
          </a>
          <a href="#console" onClick={onClose}>
            <CloseIcon onClick={onClose} />
          </a>
        </span>

        <div align="center" style={style} className="__react_modal_image__caption">{carouselState.currentTitle()}</div>

        <span className="__react_modal_image__icon_menu __left_float">
          <a href="#console" onClick={goToPreviousImage}>
            <LeftArrowIcon />
          </a>
          <a href="#console" onClick={goToNextImage}>
            <RightArrowIcon onClick={onClose} />
          </a>
        </span>
      </div>
    );
  }

  /**
    Defines the image to be displayed by the carousel.
  */
  const ImageContainer = () => {
    return (
      <div className="__react_modal_image__modal_content"
        onMouseDown={imageClickedEvent}
        onTouchStart={imageClickedEvent}
      >
        <img
          id="colorgraph_image"
          className="__react_modal_image__medium_img"
          src={carouselState.currentSource()}
          alt={carouselState.currentTitle()}
        />
      </div>
    );
  }

  // Return the carousel if it is open, null if not.
  return !carouselState.open ? null : (
    <div>
      <div className="__react_modal_image__modal_container">
        <Header />
        <ImageContainer />
      </div>
    </div>
  );
}

export default GraphCarousel;
