import React from 'react';
import { MainContentSection } from '../main-content/MainContent';
import './latex.css';

const Article = React.memo((props) => {
  return (
    <MainContentSection>
      <div dangerouslySetInnerHTML={{__html: props.content}}/>
    </MainContentSection>
  );
});

export default Article;
