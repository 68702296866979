import React from 'react';

import Card from 'react-bootstrap/Card';

import { MainContentSection } from '../main-content/MainContent';

import LeftArrowIcon from '../icons/LeftArrowIcon';
import RightArrowIcon from '../icons/RightArrowIcon';

import './youtube_video.css';

const YoutubeVideo = React.memo((props) => {
  if (props.video == null) return;

  return (
    <Card>
      <Card.Header>
        <h4 className="video-title">{props.video.title}</h4>
        <span className="video-arrows">
          <span onClick={props.previousVideo}><a>
            <LeftArrowIcon color="#aaaaaa" />
          </a></span>
          <span onClick={props.nextVideo}><a>
            <RightArrowIcon color="#aaaaaa" />
          </a></span>
        </span>
      </Card.Header>

      <Card.Body>
        <div className="background-black video-box">
          <iframe
            frameborder="0"
            allowfullscreen="allowfullscreen"
            src={props.video.video_url}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>

        <Card.Text>
          <div className="video-description">
            <h6>About</h6>
            <p>{props.video.description}</p>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
});

export default YoutubeVideo;
