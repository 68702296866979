import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const InnerNav = (props) => (
  <div align="center">
    <h1>{props.title}</h1>

    <Navbar expand="lg" style={{ backgroundColor: "rgb(238, 236, 235) !important" }}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {props.children}
        </Nav>
      </Navbar.Collapse>
    </Navbar>

    <hr />
  </div>
);

export default InnerNav;