import React from 'react';

import Article from '../../components/article/Article';
import { MainContent } from '../../components/main-content/MainContent';

const artOfMathProof = require('../../assets/html/art_of_math_proof.html');

const ArtOfMathProofPage = () => {
  return (
    <MainContent>
      <Article content={artOfMathProof}/>
    </MainContent>
  );
};

export default ArtOfMathProofPage;
