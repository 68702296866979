import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Cycle from './cycle/Cycle';

import './awkward_state_machine.css';

class AwkwardStateMachine extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activators: props.activators,
      nonActivators: props.nonActivators,
      positions: [0],
      lengths: [props.activators + props.nonActivators],
      iteration: 0
    }
  }

  nextState(e) {
    let anyActive = false;
    let positions = this.state.positions.map((pos, index) => {
      let nextPos = (this.state.lengths[index] - 1 === pos) ? 0 : (pos + 1);
      anyActive = anyActive || (nextPos < this.state.activators);
      return nextPos;
    });

    if (!anyActive) {
      positions.push(0);
      this.state.lengths.push(this.state.iteration + this.state.activators + this.state.nonActivators + 1);
    }

    this.setState({
      positions: positions,
      iteration: this.state.iteration + 1
    });
  }

  createCycles() {
    return this.state.positions.map((pos, i) => {
      return (<Cycle type="cycle" key={i} activators={this.state.activators} nonActivators={this.state.lengths[i] - this.state.activators} active={pos} />);
    });
  }

  createBranch() {
    let branchLength = this.state.iteration + this.state.activators + this.state.nonActivators;
    let nonActivators = branchLength - this.state.activators + 1;
    return (
      <Cycle key="branch" type="branch" activators={this.state.activators} nonActivators={nonActivators} active={branchLength} />
    );
  }

  updateAsm(e) {
    let name = e.target.name;
    let value = Number(e.target.value);
    if (value === 0) {
      value = "";
    }
    let activators = null;
    let nonActivators = null;
    if (name === 'activators') {
      activators = value;
      nonActivators = this.state.nonActivators;
    } else {
      activators = this.state.activators;
      nonActivators = value;
    }
    this.setState({
      activators: activators,
      nonActivators: nonActivators,
      positions: [0],
      lengths: [activators + nonActivators],
      iteration: 0
    });
  }

  render() {

    return (
      <div className="asm">
        <Form>
          <Row>
            <Col sm={12} md={6}>
              <Form.Label>Number of Activators</Form.Label>
              <Form.Control min="1" type="number" placeholder="The number of active (green) nodes" name="activators"
                value={this.state.activators} onChange={e => { this.updateAsm(e) }} />
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Number of Non-Activators</Form.Label>
              <Form.Control min="1" type="number" placeholder="The number of inactive (red) nodes within the first cycle" name="nonActivators"
                value={this.state.nonActivators} onChange={e => { this.updateAsm(e) }} />
            </Col>
          </Row>
          <Button style={{ marginTop: "3vh" }} onClick={e => { this.nextState(e) }}>Next State</Button>
        </Form>
        <div style={{ margin: "3vh", marginLeft: '0' }}>
          {this.createCycles()}
          {this.createBranch()}
        </div>
      </div>
    );
  }
}

export default AwkwardStateMachine;