import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import FunctionInput from './function-input/FunctionInput';
import GraphCarousel from './graph-carousel/GraphCarousel';

import RefreshIcon from '../../icons/RefreshIcon';

import CarouselState from './carousel.state';

import ColorGraphService from '../../../services/colorgraph.service.js';

import { randomGraph } from '../../../tools/graphInputProducer';

class Console extends React.Component {

  colorGraphService : ColorGraphService;

  constructor(props) {
    super(props);

    this.colorGraphService = new ColorGraphService();

    this.state = {
      configuration: randomGraph(),
      carouselState: new CarouselState(this.updateCarousel, { open: false }),
      hasChangedSinceLastGraph: true,
      page: "info"
    };
  }

  /**
    Callback used by the refresh icon to update the console
    with a new 'random' graph
  */
  setRandomGraph = () => {
    this.setState({
      configuration: randomGraph(),
      hasChangedSinceLastGraph: true
    });
  }

  /**
    Callback to set a new CarouselState.

    Note: This callback can be accessed on a CarouselState object
    via the #forceUpdate() method.
  */
  updateCarousel = (carouselState) => {
    this.setState({
      carouselState: carouselState
    });
  }

  /**
    Callback to update the graph config data when the user starts typing
  */
  updateConfiguration = (event) => {
    let config = this.state.configuration;
    config[event.target.name] = event.target.value;

    this.setState({
      configuration: config,
      hasChangedSinceLastGraph: true
    });
  }

  /**
    1. Updates the graphs with the data in the console.
    2. Opens the graph modal with the loading gif while the graphs are being created.
    3. Replaces the loading gif with the graphs once they arrive.

    In the event the data in the console has not changed since the graphs were last
    produced, then the graph modal will simply open to the previous graphs (in the
    previous position).
  */
  updateGraphs = () => {
    // Don't reproduce the graph if it hasn't changed
    if (!this.state.hasChangedSinceLastGraph) {
      this.setState({ carouselState: this.state.carouselState.openCarousel() });
      return;
    }

    this.colorGraphService.createGraphs(this.state.configuration).then(
      this.updateGraphsCallback
    ).catch(this.updateGraphsFailureCallback);

    // Clear the previous carousel
    this.setState({ carouselState: new CarouselState(this.updateCarousel) });
  }

  /**
    Handles the (successful) response from the server when updating the graphs.
  */
  updateGraphsCallback = (response) => {
    if (response && response.data && response.data.images) {
      this.setState({
        carouselState: this.state.carouselState.updateImageLinks(response.data.images).openCarousel(),
        hasChangedSinceLastGraph: false
      })
    } else {
      console.log("An error occurred", response);
      alert("An error occurred!");
      this.state.carouselState.closeCarousel().forceUpdate();
    }
  }

  /**
    Handles a failure response from the server when updating the graphs.
  */
  updateGraphsFailureCallback = (jq, status, thrown) => {
    console.log(status, thrown);
    console.log(jq);

    this.setState({
      carouselState: this.state.carouselState.closeCarousel()
    });
  }

  render() {
    return (
      <div>
        <RefreshIcon onClick={this.setRandomGraph}/>
        <div align="center">
          <GraphCarousel carouselState={this.state.carouselState} />

          <Form style={{ padding: "3vh", paddingTop: "26px" }}>
            <FunctionInput
              index={1}
              onChange={this.updateConfiguration}
              function={this.state.configuration.function1}
              xDomain={this.state.configuration.xDomain1}
              yDomain={this.state.configuration.yDomain1}
            />
            <FunctionInput
              index={2}
              onChange={this.updateConfiguration}
              function={this.state.configuration.function2}
              xDomain={this.state.configuration.xDomain2}
              yDomain={this.state.configuration.yDomain2}
            />
            <FunctionInput
              index={3}
              onChange={this.updateConfiguration}
              function={this.state.configuration.function3}
              xDomain={this.state.configuration.xDomain3}
              yDomain={this.state.configuration.yDomain3}
            />
          </Form>
          <Button onClick={this.updateGraphs}>Produce Graph</Button>
        </div>
      </div>
    );
  }
}

export default Console;
