import React from 'react';
import './main_content.css';

const MainContent = React.memo((props) => {
  return (
    <div className="main-content-container">
      {props.children}
    </div>
  );
});

const MainContentSection = React.memo((props) => {
  return (
    <div className="main-content-section">
      {props.children}
    </div>
  );
});

export { MainContent, MainContentSection };
