import React from 'react';

import './cycle.css';

function length(props) {
  return props.activators + props.nonActivators;
}

const CYCLE_TEXT = {
  branch: (props) => { return 'Branch Length: ' + length(props) },
  cycle: (props) => { return props.active + ' : ' + length(props) },
  modulus: (props) => { return props.iteration + ' mod ' + props.activators + ' = ' + props.active }
}

const Cycle = (props) => {
  let activators = [];
  let nonActivators = [];
  for (let i = 0; i < props.activators; i++) {
    let className = props.active === i ? "led-green" : "dull-green";
    activators.push(
      <div key={i} className="led-box"><div className={className}></div></div>
    );
  }
  let length = activators.length;
  for (let i = 0; i < props.nonActivators; i++) {
    let className = props.active === (i + length) ? "led-red" : "dull-red";
    nonActivators.push(
      <div key={i} className="led-box"><div className={className}></div></div>
    );
  }
  let cycleText = CYCLE_TEXT[props.type](props);
  return (
    <div className="cycle">
      <h5>{cycleText}</h5>
      {activators}
      {nonActivators}
    </div>
  );
};

export default Cycle;
