import React from 'react';

// You can add this to the console page to update the operations for the user.
export const RefreshIcon = (props) => (
  <span style={{float: 'left', marginBottom: '-1px'}}>
    <a href="#console" onClick={props.onClick} >
      <svg
        fill="#000000"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/1999/svg"
      >
        <path d="M 7.59375 3 L 9.0625 5 L 13 5 C 16.324219 5 19 7.675781 19 11 L 19 15 L 16 15 L 20 20.46875 L 24 15 L 21 15 L 21 11 C 21 6.59375 17.40625 3 13 3 Z M 4 3.53125 L 0 9 L 3 9 L 3 13 C 3 17.40625 6.59375 21 11 21 L 16.40625 21 L 14.9375 19 L 11 19 C 7.675781 19 5 16.324219 5 13 L 5 9 L 8 9 Z"/>
      </svg>
    </a>
  </span>
)

export default RefreshIcon;
