import React from 'react';

import { MainContent, MainContentSection } from '../../components/main-content/MainContent';
import YoutubeVideo from '../../components/youtube-video/YoutubeVideo';

import VideoService from '../../services/video.service';

class VideosPage extends React.Component {

  videoService : VideoService;

  constructor(props) {
    super(props);

    this.videoService = new VideoService();

    this.state = {
      videos: [],
      current_video: null,
      current_video_index: null
    };
  }

  componentDidMount() {
    this.videoService.fetchVideos().then(response => {
      this.setState({
          videos: response.data,
          current_video: response.data[0],
          current_video_index: 0
      })
    }).catch(error => console.log(error))
  }

  next_video() {
    let current_index = this.state.current_video_index;
    if (current_index === null) return;

    current_index = (current_index + 1) % (this.state.videos.length);
    this.setState({
      current_video: this.state.videos[current_index],
      current_video_index: current_index
    });
  }

  previous_video() {
    let current_index = this.state.current_video_index;
    if (current_index === null) return;

    if (current_index === 0) {
      current_index = this.state.videos.length - 1;
    } else {
      current_index -= 1;
    }

    this.setState({
      current_video: this.state.videos[current_index],
      current_video_index: current_index
    });
  }

  render() {
    return (
      <MainContent>
        <MainContentSection>
          <YoutubeVideo
            video={this.state.current_video}
            nextVideo={(e) => { this.next_video() }}
            previousVideo={(e) => { this.previous_video() }}
          />
        </MainContentSection>
      </MainContent>
    );
  }
}

export default VideosPage;
