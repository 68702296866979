import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Link from "react-router-dom/Link";

class PageNavigation extends React.Component {

  getContextPath() {
    let contextPath = "";
    let components = window.location.href.split("/");
    for (var i = 3; i < components.length; i++) {
      contextPath += "/" + components[i];
    }
    return contextPath;
  }

  navLinkClass(path) {
    let contextPath = this.getContextPath();
    return contextPath.includes(path) ? 'active nav-link' : 'nav-link'
  }

  render() {
    return (
      <Navbar bg="light" expand="lg">
        <Link to="/" onClick={e => {this.setState({})}}><Navbar.Brand>Awkward Math</Navbar.Brand></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/articles/art_of_math_proof" onClick={e => {this.setState({})}} className={this.navLinkClass('articles')}>
              Art of Math Proof
            </Link>
            <Link to="/awkward_state_machines#console" onClick={e => {this.setState({})}} className={this.navLinkClass('awkward_state_machines')}>
              Awkward State Machines
            </Link>
            <Link to="/colorgraphing#console" onClick={e => {this.setState({})}} className={this.navLinkClass('colorgraphing')}>
              Color Graphing
            </Link>
            <Link to="/videos" onClick={e => {this.setState({})}} className={this.navLinkClass('videos')}>
              Videos
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default PageNavigation;
