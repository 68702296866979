import React from 'react';
import './math.css';

export const LEQ = '\u2264';
export const GEQ = '\u2265';

export const M = (props) => {
  return (<span className="math-text">{props.children}</span>);
};

export const ArticleSection = (props) => {
  return (<div className="article-section">{props.children}</div>)
}
