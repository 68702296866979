import React from 'react';

import './home_page.css';

const HomePage = () => {
  return (
    <header className="App-header">
      <h1 className="App-logo">Awkward Math</h1>
      <p>
        Embrace your inner mathemagician.
      </p>
    </header>
  );
};

export default HomePage;
