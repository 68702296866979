import React from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { M, ArticleSection, LEQ} from '../../math/Math';

import './operation_info.css';

const ExampleList = (props) => {
  let data = props.data;
  if (!data) {
    return null;
  }

  let length = data.length;
  return (
  <p>
    <strong>{props.name}:</strong><br/>
    {
      data.map((ele, index) => {
        if (index === length - 1) {
          return (<Badge pill className="exampleBadge" key={index} variant="info"><M>{ele}</M></Badge>);
        }
        return (<Badge pill className="exampleBadge" key={index} variant="info"><M>{ele}</M></Badge>);
      })
    }
  </p>
  );
}

const NumberOfArguments = (props) => {
  return props.arguments ? (
    <p>
      <strong>Number of Arguments:</strong><br/>
      <Badge pill variant="info" className="exampleBadge">{props.arguments}</Badge>
    </p>
  ) : null;
}

const OperationInfo = (props) => {
  return (
    <Card style={{marginBottom: "2vh"}}>
      <Card.Header><h4>{props.operationName}</h4></Card.Header>
      <Card.Body>
        <Card.Text>
          <p>{props.children}</p>
          <NumberOfArguments arguments={props.arguments} />
          <ExampleList name="Operation Handles" data={props.handles} />
          <ExampleList name="Binary Operations" data={props.operators} />
          <ExampleList name="Function Examples" data={props.functionExamples} />
          <ExampleList name="Domain Examples" data={props.domainExamples} />
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

const Site = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.title}
    </a>
  );
}

const Wikipedia = (props) => {
  return (
    <Site href={props.href} title="Wikipedia" />
  );
}

const XOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="x"
      functionExamples={["x"]}
    >
      <p>
        Within a color function, the character <M>x</M> represents the output
        of the correspoding <M>X-Domain Mapping</M> at the current point within
        the graph.
      </p>
      <p>
        The use of <M>x</M> is only valid within the context of a color function, attempting
        to use <M>x</M> within either the <M>X-Domain Mapping</M> or the <M>Y-Domain Mapping</M> will
        result in an error.
      </p>
    </OperationInfo>
  );
}

const YOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="y"
      functionExamples={["y"]}
    >
      <p>
        Within a color function, the character <M>y</M> represents the output
        of the correspoding <M>Y-Domain Mapping</M> at the current point within
        the graph.
      </p>
      <p>
        The use of <M>y</M> is only valid within the context of a color function, attempting
        to use <M>y</M> within either the <M>X-Domain Mapping</M> or the <M>Y-Domain Mapping</M> will
        result in an error.
      </p>
    </OperationInfo>
  );
}

const NOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="n"
      domainExamples={["n"]}
    >
      <p>
        Within a domain mapping, the character <M>n</M> represents the current point
        of <M>x</M> or <M>y</M> within the color graph. As such, the value
        of <M>n</M> is always an integer contained in the range: <M>0 {LEQ} n {'<'} length</M>,
        where <M>length</M> is equal to the graph's width for a <M>X-Domain Mapping</M>, or the
        graph's height for a <M>Y-Domain Mapping</M>.
      </p>
      <p>
        The use of <M>n</M> is only valid within the context of a domain mapping, attempting
        to use <M>n</M> within a color function will result in an error.
      </p>
    </OperationInfo>
  );
}

const ConstantOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Constant"
      functionExamples={["32", "-63", "0.232"]}
      domainExamples={["0", "-602", "54.45"]}
    >
      <p>
        Any integer or real number written in decimal notation.
      </p>
    </OperationInfo>
  );
}

const AdditionOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Addition"
      functionExamples={["x + y", "(x + 32)*y", "x^2 + y^2", "add(x,y)", "add(x^2, y^2)", "add(x, y, 37, x*y)",
                         "plus(x, y)", "plus(x^2, y // x)"]}
      domainExamples={["n + 27", "n^2 + n", "(n + 0.5)^3", "add(n, 250)", "add(-23, n, n^2)", "plus(n, n^3)"]}
      operators={["+"]}
      handles={["add", "plus"]}
      arguments="2 or more"
    >
      <p>
        The <i>Addition</i> operation accepts two or more arguments and returns the sum of
        all the arguments provided.
      </p>
    </OperationInfo>
  );
}

const SubtractionOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Subtraction"
      functionExamples={["x - y", "(x - 32)*y", "x^2 - y^2", "sub(x,y)", "sub(x^2, y^2)", "sub(x, y, 37, x*y)",
                         "subtract(x^3, y^2)", "minus(x^3, y^2, x, y^0.5)"]}
      domainExamples={["n - 250", "n^2 - n", "sub(n, 500)", "sub(n^4, n^3, n^2, n)", "subtract(n, 50)",
                       "subtract(ln(n), n + 250)", "minus(0.5*n, 20)"]}
      handles={["sub", "subtract", "minus"]}
      operators={["-"]}
      arguments="2 or more"
    >
      <p>
        The <i>Subtraction</i> operation accepts two or more arguments and returns the difference
        of the first argument with the sum of the proceeding arguments. For
        example, <M>sub(10, 3, 7)</M> would return <M>10 - (3 + 7) = 10 - 10 = 0</M>.
      </p>
    </OperationInfo>
  );
}

const MultiplicationOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Multiplication"
      functionExamples={["x * y", "(x | y)*y", "2*x^2 + 4*y^2", "mult(x,y)", "times(x^2, y^2)", "mult(x, y, 37, x*y)",
                         "product(x // y, y // x)", "prod(x - y, x + y)"]}
      domainExamples={["4*n", "mult(n - 50, n + 50)", "times(n, n - 25, n - 50, n - 75)",
                       "product(50, n & 50)", "prod(5*n, n + 5)"]}
      handles={['mult', "mutiply", "times", "product", "prod"]}
      operators={['*']}
      arguments="2 or more"
    >
      <p>
        The <i>Multiplication</i> operation accepts two or more arguments and returns the product
        of all the arguments provided.
      </p>
    </OperationInfo>
  );
}

const DivisionOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Division"
      functionExamples={["x / y", "(x | y) / y", "2*x^2 / 4*y^2", "div(x,y)", "div(x^2, y^2)"]}
      domainExamples={["n / 2", "500 / n", "div(n^2, n - 50)", "divide(n + 5, n - 5)"]}
      arguments="2"
      handles={["div", "divide"]}
      operators={['/']}
    >
      <p>
        The <i>Division</i> operation accepts two arguments and returns the result of
        diving the first argument by the second. In the event the second argument
        is <M>0</M>, the division operation returns <M>0</M>.
      </p>
    </OperationInfo>
  );
}

const PowerOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Power"
      functionExamples={["x^2", "(x | y)^5", "2*x^2 + 4*y^2", "pow(x*y,2)", "pow(x, y & x)"]}
      domainExamples={["n^2", "pow(n, 2)", "power(n, 0.5)", "raise(n + 50, 2)"]}
      handles={["pow", "power", "raise"]}
      operators={["^"]}
      arguments="2"
    >
      <p>
        The <i>Power</i> operation accepts two arguments and returns the result of raising
        the first argument to the power of the second argument.
      </p>
    </OperationInfo>
  );
}

const ModulusOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Modulus"
      functionExamples={["x // y", "(x | y) // y", "2*x^2 // 4*y^2", "mod(x,y)", "modulus(x^2, y^2)"]}
      domainExamples={["n // 50", "500 // n", "mod(n^2, 100)", "modulus(sin(n), cos(n))"]}
      handles={["mod", "modulus"]}
      operators={['//']}
      arguments="2"
    >
      <p>
        The <i>Modulus</i> opeation accepts two arguments and returns the remainder of the first
        argument when divided by the second argument. In the event the second argument
        is <M>0</M>, the modulus operation is defined to return <M>0</M>.
      </p>
      <p>
        Learn more about modular arithmetic on <Wikipedia href="https://en.wikipedia.org/wiki/Modular_arithmetic" />
      </p>
    </OperationInfo>
  );
}

const AbsoluteValueOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Absolute Value"
      functionExamples={["abs(x - y)", "absolute(x^3)"]}
      domainExamples={["abs(-1*n + ln(n))", "absolute(n - 250)"]}
      handles={["abs", "absolute"]}
      arguments="1"
    >
      The <i>Absolute Value</i> operation accepts a single argument and returns
      it's absolute value.
    </OperationInfo>
  );
}

const OrOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logical: OR"
      functionExamples={["x | y", "or(x^2, y^2)"]}
      domainExamples={["n | (n - 50)", "or(n - 25, n + 25)"]}
      handles={["or"]}
      operators={["|"]}
      arguments="2"
    >
      <p>
        The <i>OR</i> operation accepts two operations as input. The values
        from both arguments are rounded to the nearest integer, and the bitwise
        <i>or</i> of those values is returned.
      </p>
      <p>
        Learn more about <i>logical OR</i> on <Wikipedia href="https://en.wikipedia.org/wiki/Logical_disjunction" />.
      </p>
    </OperationInfo>
  );
}

const AndOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logical: AND"
      functionExamples={["x & y", "and(x^2, y^2)"]}
      domainExamples={["n & (n - 50)", "and(n - 25, n + 25)"]}
      handles={['and']}
      operators={['&']}
      arguments="2"
    >
      <p>
        The <i>AND</i> operation accepts two operations as input. The values
        from both arguments are rounded to the nearest integer, and the bitwise
        <i>and</i> of those values is returned.
      </p>
      <p>
        Learn more about <i>logical AND</i> on <Wikipedia href="https://en.wikipedia.org/wiki/Logical_conjunction" />.
      </p>
    </OperationInfo>
  );
}

const XorOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logical: XOR"
      functionExamples={["x || y", "xor(x^2, y^2)"]}
      domainExamples={["n || (n - 50)", "xor(n - 25, n + 25)"]}
      handles={["xor"]}
      operators={['||']}
      arguments="2"
    >
      <p>
        The <i>XOR</i> operation accepts two operations as input. The values
        from both arguments are rounded to the nearest integer, and the bitwise
        <i>xor</i> of those values is returned.
      </p>
      <p>
        Learn more
        about <i>logical XOR</i> on <Wikipedia href="https://en.wikipedia.org/wiki/Exclusive_or" />.
      </p>
    </OperationInfo>
  );
}

const IffOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logical: If and Only If"
      functionExamples={["x <=> y", "iff(x^2, y^2)"]}
      domainExamples={["n <=> (n - 50)", "iff(n - 25, n + 25)"]}
      handles={["iff", "ifandonlyif"]}
      operators={["<=>"]}
      arguments="2"
    >
      <p>
        The <i>If and Only If</i> operation accepts two operations as input. The values
        from both arguments are rounded to the nearest integer, and the bitwise
        <i>if and only if</i> of those values is returned.
      </p>
      <p>
        Learn more about <i>logical If and Only If</i> on <Wikipedia href="https://en.wikipedia.org/wiki/If_and_only_if" />.
      </p>
    </OperationInfo>
  );
}

const ImpliesOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logical: Implies"
      functionExamples={["x => y", "implies(x^2, y^2)"]}
      domainExamples={["n => (n - 50)", "implies(n - 25, n + 25)"]}
      handles={["implies"]}
      operators={["=>"]}
      arguments="2"
    >
      <p>
        The <i>Implies</i> operation accepts two operations as input. The values
        from both arguments are rounded to the nearest integer, and the bitwise
        <i>implies</i> of those values is returned.
      </p>
      <p>
        Learn more about <i>logical Implies</i> on <Wikipedia href="https://en.wikipedia.org/wiki/Material_conditional" />.
      </p>
    </OperationInfo>
  );
}

const NandOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logical: Nand"
      functionExamples={["x -& y", "nand(x^2, y^2)"]}
      domainExamples={["n -& (n - 50)", "nand(n - 25, n + 25)"]}
      handles={['nand', "notand"]}
      operators={['-&', '!&']}
      arguments="2"
    >
      <p>
        The <i>Nand</i> operation accepts two operations as input. The value
        from both arguments are rounded to the nearest integer, and the bitwise
        <i>nand</i> of those values is returned.
      </p>
      <p>
        Learn more about <i>logical NAND</i> on <Wikipedia href="https://en.wikipedia.org/wiki/NAND_logic" />.
      </p>
    </OperationInfo>
  );
}

const NorOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logical: Nor"
      functionExamples={["x -| y", "nor(x^2, y^2)"]}
      domainExamples={["n -| (n - 50)", "nor(n - 25, n + 25)"]}
      handles={['nor', 'notor']}
      operators={['-|', '!|']}
      arguments="2"
    >
      <p>
        The <i>Nor</i> operation accepts two operations as input. The values
        from both arguments are rounded to the nearest integer, and the bitwise
        <i>nor</i> of those values is returned.
      </p>
      <p>
        Learn more about <i>logical NOR</i> on <Wikipedia href="https://en.wikipedia.org/wiki/NOR_logic" />
      </p>
    </OperationInfo>
  );
}

const NotOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logical: Not"
      functionExamples={["not(x)", "not(x || y)"]}
      domainExamples={["not(n)", "not(n^2)"]}
      handles={['not']}
      arguments="1"
    >
      <p>
        The <i>Not</i> operation accepts a single operation as input. The value
        produced by this argument is rounded to the nearest integer, and the
        bitwise <i>not</i> of that value is returned.
      </p>
      <p>
        Learn more about <i>logical NOT</i> on <Wikipedia href="https://en.wikipedia.org/wiki/Negation" />
      </p>
    </OperationInfo>
  );
}

const LessThanOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Less Than"
      functionExamples={["x < y", "lt(x - y, y - x)"]}
      domainExamples={["(n - abs(n)) < 0", "less(n - abs(n), 0)"]}
      handles={['lt', 'less', 'lessthan']}
      operators={['<']}
      arguments="2"
    >
      <p>
        The <i>Less Than</i> operation accepts two arguments as input. If the first argument is strictly less than the second argument, then <M>1</M> is returned; otherwise, <M>0</M> is returned.
      </p>
    </OperationInfo>
  );
}

const LessThanEqualsOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Less Than Equals"
      functionExamples={["x <= y", "leq(x - y, y - x)"]}
      domainExamples={["(n - abs(n)) <= 0", "lessequals(n - abs(n), 0)"]}
      handles={['leq', 'lessequals']}
      operators={['<=']}
      arguments="2"
    >
      <p>
        The <i>Less Than Equals</i> operation accepts two arguments as input. If the first argument is less than or equal to the second argument, then <M>1</M> is returned; otherwise, <M>0</M> is returned.
      </p>
    </OperationInfo>
  );
}


const EqualsOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Equals"
      functionExamples={["(x & y) = 0", "equals(x, y)"]}
      domainExamples={["(n - abs(n)) = 0", "equals(n - abs(n), 0)"]}
      handles={['equals', 'eq']}
      operators={['=']}
      arguments="2"
    >
      <p>
        The <i>Equals</i> operation accepts two arguments as input. If the given
        arguments are equal, then <M>1</M> is returned. If the given arguments are
        not equal, then <M>0</M> is returned.
      </p>
    </OperationInfo>
  );
}

const GreaterThanOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Greater Than"
      functionExamples={["x > y", "gt(x - y, y - x)"]}
      domainExamples={["(n - abs(n)) > 0", "greater(n - abs(n), 0)"]}
      handles={['gt', 'greater']}
      operators={['>']}
      arguments="2"
    >
      <p>
        The <i>Greater Than</i> operation accepts two arguments as input. If the first argument is strictly greater than the second argument, then <M>1</M> is returned; otherwise, <M>0</M> is returned.
      </p>
    </OperationInfo>
  );
}

const GreaterThanEqualsOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Greater Than Equals"
      functionExamples={["x >= y", "geq(x - y, y - x)"]}
      domainExamples={["(n - abs(n)) >= 0", "greaterequals(n - abs(n), 0)"]}
      handles={['geq', 'greaterequals']}
      operators={['>=']}
      arguments="2"
    >
      <p>
        The <i>Greater Than Equals</i> operation accepts two arguments as input. If the first argument is greater than or equal to the second argument, then <M>1</M> is returned; otherwise, <M>0</M> is returned.
      </p>
    </OperationInfo>
  );
}

const MaxOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Max"
      functionExamples={["max(x, y)", "greatest(x - y, y - x)"]}
      domainExamples={["max(180*sin(n), 180*cos(n))"]}
      handles={['max', 'greatest']}
      arguments="2"
    >
      <p>
        The <i>Max</i> operation accepts two arguments as input and returns the argument of greatest value.
      </p>
    </OperationInfo>
  );
}

const MinOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Min"
      functionExamples={["min(x, y)", "least(x - y, y - x)"]}
      domainExamples={["min(180*sin(n), 180*cos(n))"]}
      handles={['min', 'least']}
      arguments="2"
    >
      <p>
        The <i>Min</i> operation accepts two arguments as input and returns the argument of least value.
      </p>
    </OperationInfo>
  );
}


const WithinRangeOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Within Range"
      functionExamples={["within(-1, 1, x / y)", "contains(x, y, 0)"]}
      domainExamples={["within(64, 128, n)"]}
      handles={['within', 'contains']}
      arguments="2"
    >
      <p>
        The <i>Within Range</i> operation accepts three arguments as input. This operation checks if the third argument is greater than or equal to the least of the first two arguments and less than or equal to the greater of the first two arguments. If the third argument is contained within this range, then <M>1</M> is returned; otherwise, <M>0</M> is returned.
      </p>
    </OperationInfo>
  );
}

const OutsideRangeOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Outside Range"
      functionExamples={["out(-1, 1, x / y)", "outside(x, y, 0)"]}
      domainExamples={["out(64, 128, n)"]}
      handles={['out', 'outside']}
      arguments="2"
    >
      <p>
        The <i>Outside Range</i> operation accepts three arguments as input. This operation performs the opposite check of the <i>Within Range</i> operation. As such, this operation checks if the third argument is strictly less than the least of the first two arguments or strictly greater than the greater of the first two arguments. If the third argument meets either criteria, then <M>1</M> is returned; otherwise, <M>0</M> is returned.
      </p>
    </OperationInfo>
  );
}


const CeilingOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Rounding: Ceiling"
      functionExamples={["ceil(x)", "ceiling(x / y)"]}
      domainExamples={["ceil(n / 2)", "ceiling(n*sin(n))"]}
      handles={['ceil', 'ceiling']}
      arguments="1"
    >
      <p>
        The <i>Ceiling</i> operation accepts a single argument and returns
        the least integer of equal or greater value compared to the argument.
      </p>
    </OperationInfo>
  );
}

const FloorOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Rounding: Floor"
      functionExamples={["floor(x)", "floor(x / y)"]}
      domainExamples={["floor(n / 2)", "floor(n*sin(n))"]}
      handles={['floor']}
      arguments="1"
    >
      <p>
        The <i>Floor</i> operation accepts a single argument and returns
        the greatest integer of equal or lesser value compared to the argument.
      </p>
    </OperationInfo>
  );
}

const RoundToNearestOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Rounding: Round to Nearest Integer"
      functionExamples={["round(x)", "nearest(x / y)"]}
      domainExamples={["round(n / 2)", "nearest(n*sin(n))"]}
      handles={['round', 'nearest', 'int', 'integer']}
      arguments="1"
    >
      <p>
        The <i>Round to Nearest Integer</i> operation accepts a single argument as input
        and returns that argument rounded to the nearest integer.
      </p>
    </OperationInfo>
  );
}

const SinOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Trigonometry: Sine"
      functionExamples={["sin(x)", "sin(x^2 + y^2)"]}
      domainExamples={["sin(n)", "sine(n^2)"]}
      handles={['sin', 'sine']}
      arguments="1"
    >
      <p>
        The <i>Sine</i> operation accepts a single argument as input and returns
        its trigometric sine of it's value.
      </p>
      <p>
        Learn more about sine on <Wikipedia href="https://en.wikipedia.org/wiki/Trigonometric_functions" />.
      </p>
    </OperationInfo>
  );
}

const CosinOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Trigonometry: Cosine"
      functionExamples={["cos(x)", "cos(x^2 + y^2)"]}
      domainExamples={["cos(n)", "cosine(n^2)"]}
      handles={['cos', 'cosine']}
      arguments="1"
    >
      <p>
        The <i>Cosine</i> operation accepts a single argument as input and returns
        its trigometric cosine of it's value.
      </p>
      <p>
        Learn more about cosine on <Wikipedia href="https://en.wikipedia.org/wiki/Trigonometric_functions" />.
      </p>
    </OperationInfo>
  );
}

const TangentOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Trigonometry: Tangent"
      functionExamples={["tan(x)", "tangent(x^2 + y^2)"]}
      domainExamples={["tan(n)", "tangent(n^2)"]}
      handles={['tan', 'tangent']}
      arguments="1"
    >
      <p>
        The <i>Tangent</i> operation accepts a single argument as input and returns
        its trigometric tangent of it's value.
      </p>
      <p>
        Learn more about tangent on <Wikipedia href="https://en.wikipedia.org/wiki/Trigonometric_functions" />.
      </p>
    </OperationInfo>
  );
}

const SecantOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Trigonometry: Secant"
      functionExamples={["sec(x)", "secant(x^2 + y^2)"]}
      domainExamples={["sec(n)", "secant(n^2)"]}
      handles={['sec', 'secant']}
      arguments="1"
    >
      <p>
        The <i>Secant</i> operation accepts a single argument as input and returns
        its trigometric secant of it's value. In the event the sine of the input
        is <M>0</M>, then <M>0</M> is returned.
      </p>
      <p>
        Learn more about secant on <Wikipedia href="https://en.wikipedia.org/wiki/Trigonometric_functions" />.
      </p> 
    </OperationInfo>
  );
}

const CosecantOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Trigonometry: Cosecant"
      functionExamples={["csc(x)", "cosecant(x^2 + y^2)"]}
      domainExamples={["csc(n)", "cosecant(n^2)"]}
      handles={['csc', 'cosecant']}
      arguments="1"
    >
      <p>
        The <i>Cosecant</i> operation accepts a single argument as input and returns
        its trigometric cosecant of it's value. In the event the cosine of the input
        is <M>0</M>, then <M>0</M> is returned.
      </p>
      <p>
        Learn more about cosecant on <Wikipedia href="https://en.wikipedia.org/wiki/Trigonometric_functions" />.
      </p>
    </OperationInfo>
  );
}

const CotangentOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Trigonometry: Cotangent"
      functionExamples={["cot(x)", "cotangent(x^2 + y^2)"]}
      domainExamples={["cot(n)", "cotangent(n^2)"]}
      handles={['cot', 'cotangent']}
      arguments="1"
    >
      <p>
        The <i>Cotangent</i> operation accepts a single argument as input and returns
        its trigometric cotangent of it's value. In the event the tangent of the input
        is <M>0</M>, then <M>0</M> is returned.
      </p>
      <p>
        Learn more about cotangent on <Wikipedia href="https://en.wikipedia.org/wiki/Trigonometric_functions" />.
      </p>
    </OperationInfo>
  );
}

const LogarithmOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Logarithm"
      functionExamples={["log(2, x)", "log(10, x^2 + y^2)"]}
      domainExamples={["log(2, n)", "logarithm(n, 500)"]}
      handles={['log', 'logarithm']}
      arguments="2"
    >
      <p>
        The <i>Logarithm</i> operation accepts two arguments as input. The operation
        uses the first argument as the base, and then calculates the logarithm of
        the second argument using that base. In the event either argument is negative,
        it's absolute value is used instead. In the event either argument is <M>0</M>,
        then <M>0</M> is returned.
      </p>
      <p>
        Learn more about logarithms
        on <a href="https://en.wikipedia.org/wiki/Logarithm" target="_blank" rel="noopener noreferrer">
          wikipedia
        </a>.
      </p>
    </OperationInfo>
  );
}

const NaturalLogOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Natural Logarithm"
      functionExamples={["ln(x)", "ln(x^2 + y^2)"]}
      domainExamples={["ln(n)", "lognat(n^2)"]}
      handles={['ln', 'natural', 'lognat', 'natlog', 'naturallogarithm']}
      arguments="1"
    >
      <p>
        The <i>Natural Logarithm</i> operation accepts a single argument as input
        and returns the natural logairthm of that value. In the event the argument
        is negative, then it's absolute value is used instead. In the event the
        argument is 0, then <M>0</M> is returned.
      </p>
      <p>
        Learn more about the natural logarithm
        on <a href="https://en.wikipedia.org/wiki/Natural_logarithm" target="_blank" rel="noopener noreferrer">
          wikipedia
        </a>.
      </p>
    </OperationInfo>
  );
}

const NthGeneratorOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Nth Generator"
      functionExamples={["nth(541, x^2 + y^2)", "gen(2131, x & y)"]}
      domainExamples={["nth(97, n)", "gen(23, n^2)"]}
      handles={['nth', 'gen', 'generator']}
      arguments="1"
    >
      <p>
        The <i>Nth Generator</i> opeartion accepts two arguments as input. This operation returns the values of the generators for a group of integers under mutliplication modulo a prime number.
      </p>
      <p>
        As in the <i>Discrete Logarith</i> and <i>Diffie-Hellman</i> operations, the first argument must be a <i>Constant</i> operation having prime number as it's value, any other input will cause an error. This value determines which group of integers to be used.
      </p>
      <p>
        The second argument can be any operation. This arguments value is automatically rounded to the nearest integer, then replaced by the remainder when divided by the prime number specified by the first argument. The remainder is then used to determine which generator for the group should be retrieved as follows: Given the remainder <M>n</M>, the <M>n</M>th least generator is returned; where a remainder of <M>0</M> indicates that the least valued generator should be returned.
      </p>
      <p>
        Learn more about the generators on <Wikipedia href="https://en.wikipedia.org/wiki/Primitive_root_modulo_n" />.
      </p>
    </OperationInfo>
  );
}

const DiscreteLogOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Discrete Logarithm"
      functionExamples={["dl(97, 7, x & y)", "dlog(23, 5, x^2 + y^2), disc(23, 5, x | y)"]}
      domainExamples={["dlog(97, 5, n)"]}
      handles={['dl', 'dlog', 'discrete', 'disc', 'discretelogarithm']}
      arguments="3"
    >
      <p>
        The <i>Discrete Logarithm</i> operation accepts three arguments as input. The first
        two arguments are used to define a group and a generator for that group to be used
        within the discrete logairthm operation. While the last argument defines value to
        solve the discrete logarithm for.
      </p>
      <p>
        The first input must
        be a <i>Constant</i> operation having a prime number as it's value, any other input will result in an error.
        The prime number will define the group size in which to calculate the logarithm in.
      </p>
      <p>
        The second input must also be a constant, and it's value must corresponds to
        one of the <i>generators</i> for chosen group.
      </p>
      <p>
        The final input can be any operation and represents the value to solve the discrete
        logairthm for within the chosen group and given generator. This value is automatically
        rounded to the nearest integer prior to attempting to solve the logarithm. In the event
        the logarithm cannot be solved, then <M>-1</M> is returned.
      </p>
      <p>
        Learn more about discrete logarithms on <Wikipedia href="https://en.wikipedia.org/wiki/Discrete_logarithm" />.
      </p>
    </OperationInfo>
  );
}

const DiffieHellmanOperationInfo = (props) => {
  return (
    <OperationInfo
      operationName="Diffie-Hellman Key Exchange"
      functionExamples={["df(97, 7, x & y, x | y)", "dif(23, 5, x^2 + y^2, (x + y)^2)"]}
      domainExamples={["df(97, 5, n & n^2, n | n^2)"]}
      handles={["df", "dif", "diffie", "hellman", "diffiehellman"]}
      arguments="4"
    >
      <p>
        The <i>Diffie-Hellman Key Exchange</i> operation accepts four arguments as input. The arguments define the context of a key exchange, and the operation returns the shared secret produced by the defined exchange. Similar to the <i>Discrete Logarithm</i> operation, the first two arguments of the operation define a group and a generator for that group. While the last two arguments define the the 'secret keys' of the two actors within exchange.
      </p>
      <p>
        The first argument must be a <i>Constant</i> operation having a prime number as it's value. Any other input to this argument will result in an error.
      </p>
      <p>
        The second input must also be a constant, and it's value must corresponds to
        one of the <i>generators</i> for chosen group. Any other input to this argument will result in an error.
      </p>
      <p>
        The final two inputs to the Diffie Hellman operation are the 'secret' integers of the two actors in the exchange. Their values are automatically rounded to the nearest integer prior to being used within the exchange. In the event the exchange cannot be completed, then <M>-1</M> is returned.
      </p>
      <p>
        Learn more about the Diffie Hellman key exchange on <Wikipedia href="https://en.wikipedia.org/wiki/Diffie%E2%80%93Hellman_key_exchange" />.
      </p>
    </OperationInfo>
  );
}


export const OperationInfoConsole = () => {
  return (
    <div style={{padding: "2vw"}}>
    <ArticleSection>
      <Card style={{marginBottom: "2vh"}}>
        <Card.Header><div align="center"><h2>Operations</h2></div></Card.Header>
        <Card.Body>
          Listed below are all the supported operations. Each operation provides a description
          of the function; how many arguments the operation consumes, along with any constraints
          on those arguments; the operations's handles (if applicable); the operation's
          binary operators (if applicable); along with examples of using the operation
          as both a color function or a domain mapping.
        </Card.Body>
      </Card>

      <XOperationInfo />
      <YOperationInfo />
      <NOperationInfo />

      <ConstantOperationInfo />

      <AdditionOperationInfo />
      <SubtractionOperationInfo />
      <MultiplicationOperationInfo />
      <DivisionOperationInfo />
      <PowerOperationInfo />

      <FloorOperationInfo />
      <CeilingOperationInfo />
      <RoundToNearestOperationInfo />
      <AbsoluteValueOperationInfo />

      <SinOperationInfo />
      <CosinOperationInfo />
      <TangentOperationInfo />
      <SecantOperationInfo />
      <CosecantOperationInfo />
      <CotangentOperationInfo />

      <LogarithmOperationInfo />
      <NaturalLogOperationInfo />

      <MaxOperationInfo />
      <MinOperationInfo />

      <ModulusOperationInfo />

      <LessThanOperationInfo />
      <LessThanEqualsOperationInfo />
      <EqualsOperationInfo />
      <GreaterThanOperationInfo />
      <GreaterThanEqualsOperationInfo />

      <WithinRangeOperationInfo />
      <OutsideRangeOperationInfo />

      <OrOperationInfo />
      <AndOperationInfo />
      <XorOperationInfo />
      <ImpliesOperationInfo />
      <IffOperationInfo />
      <NorOperationInfo/>
      <NandOperationInfo />
      <NotOperationInfo />

      <DiscreteLogOperationInfo />
      <DiffieHellmanOperationInfo />
      <NthGeneratorOperationInfo />

    </ArticleSection>
    </div>
  );
};


export default OperationInfoConsole;
