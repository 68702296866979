import React from 'react';

import AwkwardStateMachine from '../../components/awkward-state-machine/AwkwardStateMachine';
import Article from '../../components/article/Article';

import InnerNav from '../../components/inner-nav/InnerNav';
import { MainContent, MainContentSection } from '../../components/main-content/MainContent';

import { getContextPath } from '../../tools/general';

const awkwardStateMachines = require('../../assets/html/awkward_state_machines.html');

class AwkwardStateMachinesPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activators: 1,
      nonActivators: 1
    }
  }

  navClicked(page) {
    this.setState({
      page: page
    })
  }

  render() {
    let content = null;
    let context = getContextPath().split("#");
    let page = context[context.length - 1];

    if (page === "theory") {
      content = (<Article content={awkwardStateMachines} />);
    } else {
      page = "console";
      content = <AwkwardStateMachine activators={this.state.activators} nonActivators={this.state.nonActivators} />;
    }

    let consoleClass = page === "console" ? "nav-link active" : "nav-link";
    let theoryClass = page === "theory" ? "nav-link active" : "nav-link";

    return (
      <MainContent>
        <MainContentSection>
          <InnerNav title="Awkward State Machines">
            <a
                href="/awkward_state_machines#console"
                className={consoleClass}
                onClick={() => { this.navClicked("console") }}
              >
                Console
            </a>
              <a
                href="/awkward_state_machines#theory"
                onClick={() => { this.navClicked("theory") }}
                className={theoryClass}
              >
                Theory
            </a>
          </InnerNav>
          {content}
        </MainContentSection>
      </MainContent>
    );
  }

}

export default AwkwardStateMachinesPage;
