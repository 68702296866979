import React from 'react';
import Card from 'react-bootstrap/Card';
import { M } from '../../math/Math';

export const GraphingInfo = (props) => {
  return (
    <div style={{padding: "2vw"}}>
    <Card style={{marginBottom: "2vh"}}>
      <Card.Header><h4>What is a Color Graph?</h4></Card.Header>
      <Card.Body>
        <p>
          In a typical two-dimensional graph, we define the value of <M>y</M> via some function in terms of <M>x</M>, for instance, <M>y = f(x) = x + 5</M>. We then calculate the value of <M>y</M> over some range of <M>x</M>, and plot the resulting values on a  two-dimensional plane to produce a graph. Similarly, in a typical three-dimensional graph, we define the value of <M>z</M> via some function in terms  of <M>x</M> and <M>y</M>, for instance, <M>z = f(x,y) = x^2 + y^2</M>. We then calculate the value <M>z</M> over some range of <M>x</M> and <M>y</M>, and plot the resulting  values in three-dimensional space to produce a graph. A color graph employs the same basic principle as these two and three-dimensional graphs we are already familiar with. In a color graph, we define three functions in terms  of <M>x</M> and <M>y</M>: <M>red = r(x,y)</M>, <M>green = g(x,y)</M>, and <M>blue = b(x,y)</M>. Each of these functions defines the intensity of one of the colors in the color graph at any given point <M>(x,y)</M>. To produce a color graph, we calculate the intesity of red, green, and blue at every point within a rectangle, and then 'color' in each of those points with the color defined by the calculated intesities. For example, if <M>blue = f(x,y) = x + y</M> then  the intensity of <M>blue</M> at point <M>(2,3)</M> in the graph would be  given by <M>blue = f(2, 3) = 2 + 3 = 5</M>; similarly, the intensity at point <M>(100, 12)</M> would be <M>blue = f(100, 12) = 100 + 12 = 112</M>. Put simply, a color graph is created by defining the intensity of the colors <M>red, green, and blue</M> over the <M>x,y-plane</M>.
        </p>
        <p>
          Similar to how <M>x-axis</M> and <M>y-axis</M> from a 2-dimensional graph are perpendicular to one another, the colors <M>red</M>, <M>green</M>, and <M>blue</M> are considered perpendicular to one another. What this means is that the intensity of the color <M>red</M> is independent of the intensity of <M>blue</M> or <M>green</M>, similarly, the intensity of <M>blue</M> and green are also mutually independent of one another. This independent nature of the colors is what allows color graphs to produce mathematically meaningful images. Furthermore, a since two-dimensional graph consists of points containing two perpendicular entries <M>(x, y)</M>; and a three-dimensional graph consists of points containing three perpendicular entries <M>(x, y, z)</M>; by extensional, a color graph is five-dimensional since it consisits points containing five perpendicular entries <M>(x, y, red, green, blue)</M>.
        </p>
        <p>
          In order to avoid confusion, up until this point we've only mentioned that the colors <M>red</M>, <M>green</M>, and <M>blue</M> are used to create color graphs. However, this is not true. Just as <M>red</M>, <M>green</M>, and <M>blue</M> are all perpendicular to one another, the colors <M>cyan</M>, <M>magenta</M>, and <M>yellow</M> are also perpendicular to one another. As such, we can also produce a meaningful color graph by defining three functions that define the intensity of the colors <M>cyan</M>, <M>magenta</M>, and <M>yellow</M>.
        </p>
        <p>
          As mentioned, a color graph is defined over the points of a rectangle. The rectangle's size is defined by the graph's width and height, where each point in the rectangle corresponds to a single pixel in the resulting image. As such, the value of <M>x</M> starts at <M>0</M> in the upper-left hand corner of the image and increases by <M>1</M> for every pixel you move to the right; thus allowing for a maximum value of <M>width - 1</M>. Similarly, the value of <M>y</M> starts at <M>0</M> in the upper-left hand corner of the image and increases by <M>1</M> for every pixel you move down; thus allowing for a maximum value of <M>height - 1</M>.
        </p>
      </Card.Body>
    </Card>
    <Card style={{marginBottom: "2vh"}}>
      <Card.Header><h4>Defining a Color Graph</h4></Card.Header>
      <Card.Body>
        <p>
          Within the console, you define the three color functions by entering typing your operations within the fields labeled <i>Function 1</i>, <i>Function 2</i>, and <i>Function 3</i>. Furthermore, for each color function, you must also define two  domain mappings, <i>X-Domain</i> and <i>Y-Domain</i>. You can learn more about the specifics of defining functions and domain mappings in the corresponding sections below.
        </p>
        <p>
          Once you've defined your color functions and their respective domain mappings, you can create your graph by pressing the <i>Produce Graph</i> button located at the bottom of the console. Once clicked, the site will begin producing your graph. Since it can take up to 30 seconds to produce some graphs, you will be presented with a loading gif while the calculations are being performed. Once your graph is complete, the console will automatically replace the loading gif with the graph.
        </p>
        <p>
          When you create a graph, the console will actually produce twelve images - one image for each way we can map our functions to the colors <M>(red, green, blue)</M> or the colors <M>(cyan, magenta, yellow)</M>. You can cycle through the images by clicking directly on the current image. A click on the right-half of the image will load the next image, while a click on the left-half of the image will load the previous image. The title of the graph located within the center the toolbar at the top of the page specifies which color each of the functions is defining: the first color listed corresponds to the output from <i>Function 1</i>, the second color listed corresponds to the output from <i>Function 2</i>, and the final color listed corresponds to the output from <i>Function 3</i>. For instance, if the title reads "Red, Green, Blue", then the output from <i>Function 1</i> is mapped to the color <M>red</M>, the ouput from <i>Function 2</i> is mapped to the color <M>green</M>, andthe ouput from <i>Function 3</i> is mapped to the color <M>blue</M>. Similarly, the title "Yellow, Magenta, Cyan" implies that <i>Function 1</i> is mapped to <M>yellow</M>, <i>Function 2</i> is mapped to <M>magenta</M>, and <i>Function 3</i> is mapped to <M>cyan</M>.
        </p>
        <p>
          While viewing your graph, you can download the image using the download icon located to the left of the exit button located within the toolbar in the top-right of the page. If you would like to discontinue viewing your graph, you can click the exit button located within the toolbar in the top-right of the page; or by clicking in the empty space outside the bounds of the graph.
        </p>
        <p>
          In the event you input an invalid function or domain mapping, the console will error and your graph will not be produced. Although invalid input is the most likely cause for error, there is a chance that your error is caused by a bug. Unfortinately, there is not currently a mechanism for reporting bugs; however, we are working on supporting a bug-reporting feature in the future.
        </p>
      </Card.Body>
    </Card>
    <Card style={{marginBottom: "2vh"}}>
      <Card.Header><h4>Functions</h4></Card.Header>
      <Card.Body>
        <p>
          As described above, a color graph is defined by three color functions that define the intesity of either <M>(red, green, blue)</M> or <M>(magenta, cyan, yellow)</M> over a rectangle. When typing your functions, the character <i>x</i> is assigned the value output by the <M>x-domain mapping</M>, while the character <i>y</i> is assigned the value output by the <M>y-domain mapping</M>. You can find a list of these operations and examples in the <a href="/colorgraphing#operations" onClick={props.goToOperationsTab}>Operations Tab</a>.
        </p>
      </Card.Body>
    </Card>
    <Card>
      <Card.Header><h4>Domain Mappings</h4></Card.Header>
      <Card.Body>
        <p>
          As mentioned above, a color graph is produced by defining the intensity of three perpendicular colors for every pixel within a rectangular image, where the top-left hand corner is the point <M>(0,0)</M> with <M>x</M> increasing by <M>1</M> for every pixel you move to the right, and <M>y</M> increasing by <M>1</M> for every pixel you move down. If we were to use these values of <M>x</M> and <M>y</M> as input to our color graphs, then every color graph of the same size would have the same input. To easily add more variety to the graphs we can produce, the console allows you to define two operations: a <i>x-domain mapping</i> and a <i>y-domain mapping</i>. The <i>x-domain mapping</i> is a function that receives the <M>x-coordinate</M> of the current pixel and maps it to a new value that is then used as the value of <M>x</M> within the color function. Similarly, the <i>y-domain mapping</i> receives the <M>y-coordinate</M> of the current pixel and maps it to a new value that is then used as the value of <M>y</M> with the color function. As such, if the operation <M>f(x) = x - 250</M> is our <i>x-domain mapping</i>, the operation <M>g(y) = ln(y)</M> is our <i>y-domain mapping</i>, and <M>red(x, y) = x^2 + y^2</M> is the corresponding color function, then the intensity of the red points within the graph would be given by: <M>red(x,y) = f(x)^2 + g(y)^2 = (x - 250)^2 + ln(y)^2</M>.
        </p>
        <p>
          While functions use the characters <M>x</M> and <M>y</M> to denote input to the function, domain mappings use the character <M>n</M> to denote the coordinate of the curren pixel. For example, at pixel <M>(50, 213)</M>, the value of <M>n</M> in the <i>x-domain mapping</i> would be <M>50</M>, and the value of <M>n</M> in the <i>y-domain mapping</i> would be <M>213</M>. Domain mappings can use any of the supported operations that functions do; you can find a list of these operations and examples in the <a href="/colorgraphing#operations" onClick={props.goToOperationsTab}>Operations Tab</a>.
        </p>
      </Card.Body>
    </Card>
    </div>
  );
}

export default GraphingInfo;
