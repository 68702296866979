export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}

export const getContextPath = () => {
  let contextPath = "";
  let components = window.location.href.split("/");
  for (var i = 3; i < components.length; i++) {
    contextPath += "/" + components[i];
  }
  return contextPath;
}

export const extractPageFromContextPath = () => {
  let context = getContextPath().split("#");
  return context[context.length - 1];
}

export default {
  getRandomInt,
  getContextPath,
  extractPageFromContextPath
};