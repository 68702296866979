import React from 'react';

import InnerNav from '../../components/inner-nav/InnerNav';
import { MainContent, MainContentSection } from '../../components/main-content/MainContent';

import Console from '../../components/color-graphing/console/Console';
import GraphingInfo from '../../components/color-graphing/graphing-info/GraphingInfo';
import OperationInfo from '../../components/color-graphing/operation-info/OperationInfo';

import { extractPageFromContextPath } from '../../tools/general';

class ColorGraphPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { page: "info" };
  }

  navClicked(page) {
    this.setState({ page: page })
  }

  render() {

    let content = null;
    let page = extractPageFromContextPath();
    if (page === "console") {
      content = (<Console />);
    } else if (page === 'operations') {
      content = (<OperationInfo />);
    } else {
      page = "info";
      content = (<GraphingInfo goToOperationsTab={() => { this.navClicked("operations") }} />);
    }

    let consoleClass = page === "console" ? "nav-link active" : "nav-link";
    let infoClass = page === "info" ? "nav-link active" : "nav-link";
    let operationsClass = page === "operations" ? "nav-link active" : "nav-link";

    return (
      <MainContent>
        <MainContentSection>
          <InnerNav title="Color Graphing">
            <a
                href="/colorgraphing#console"
                className={consoleClass}
                onClick={() => { this.navClicked("console") }}
              >
                Console
            </a>
              <a
                href="/colorgraphing#info"
                className={infoClass}
                onClick={() => { this.navClicked("info") }}
              >
                Information
            </a>
              <a
                href="/colorgraphing#operations"
                onClick={() => { this.navClicked("operations") }}
                className={operationsClass}
              >
                Operations
            </a>
          </InnerNav>
          {content}
        </MainContentSection>
      </MainContent>
    )
  }
}

export default ColorGraphPage;
