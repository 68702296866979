class ColorGraphConfiguration {

  function1 : string;
  function2 : string;
  function3 : string;

  xDomain1 : string;
  xDomain2 : string;
  xDomain3 : string;

  yDomain1 : string;
  yDomain2 : string;
  yDomain3 : string;

  height: number;
  width: number;

  toJson() : string {
    return JSON.stringify({
      function1: this.function1,
      xDomain1: this.xDomain1,
      yDomain1: this.yDomain1,
      function2: this.function2,
      xDomain2: this.xDomain2,
      yDomain2: this.yDomain2,
      function3: this.function3,
      xDomain3: this.xDomain3,
      yDomain3: this.yDomain3,
      width: this.width,
      height: this.height
    });
  }
}

export default ColorGraphConfiguration;
